import axios from 'axios'
import {  Message } from 'element-ui'
import router from "../router";  //MessageBox,

// create an axios instance
const service = axios.create({
  baseURL: 'https://api.gbservice.cn/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// // request interceptor
// service.interceptors.request.use(
//   config => {
//     // do something before request is sent
//
//     if (store.getters.token) {
//       // let each request carry token
//       // ['X-Token'] is a custom headers key
//       // please modify it according to the actual situation
//       config.headers['X-Token'] = getToken()
//       if (config.method === 'post') {
//         config.data = {
//           token: getToken(),
//           ...config.data
//         }
//       } else if (config.method === 'get') {
//         config.params = {
//           token: getToken(),
//           ...config.params
//         }
//       }
//     }
//     return config
//   },
//   error => {
//     // do something with request error
//     console.log(error) // for debug
//     return Promise.reject(error)
//   }
// )

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // console.log(response)
    const res = response.data
    if (response.request.responseURL!=undefined && response.request.responseURL!='' && response.request.responseURL.indexOf("export")!=-1){
      return response;
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code != 200) {
      if (res.code == 500){
        Message({
          message: '系统错误，请联系管理员',
          type: 'error',
          duration: 5 * 1000
        })
      } else if(res.code == 401){
        Message({
          message: '登录已失效，请重新登录',
          type: 'error',
          duration: 5 * 1000
        });
        router.push({
          path: '/login'
        })

      }else {
        Message({
          message: res.message || res.mseeage || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(new Error(res.message|| res.mseeage || 'Error'))
    } else {
      return res
    }
  },
  error => {
    // console.log('err' + error) // for debug
    Message({
      message: '请检查网络',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service

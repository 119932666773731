import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router";
import {getInfo} from "../api/user";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    // role: ['admin','enterprise','expert']
    role: ['enterprise'],  //1管理员 2专家 3企业
    user: '',
  },
  mutations: {
    set_user(state,user){
      let role = [];
      // if (!user.type){
      //   role  = [1];
      // }else {
      //   role.push(user.type);
      // }
      role.push(user.type);
      localStorage.setItem('energy_token',user.token)
      localStorage.setItem('energy_role',role)
      localStorage.setItem('energy_user',JSON.stringify(user))
      state.user = user;
      state.token = user.token;
      state.role = role;
    },
    set_green_user(state,user){
      let role = [1];
      // if (!user.type){
      //   role  = [1];
      // }else {
      //   role.push(user.type);
      // }
      role.push(user.type);
      localStorage.setItem('energy_role',role)
      localStorage.setItem('energy_user',JSON.stringify(user))
      state.user = user;
      state.role = role;
    },
    set_token(state,token){
      state.token = token
    },
    set_role(state,role){
      state.role = role
    },
    login_out(state){
      localStorage.removeItem('energy_user');
      localStorage.removeItem('energy_token');
      localStorage.removeItem('energy_role');
      state.user = '';
      state.token = '';
      state.role = '';
      router.push({path: '/login'})
    }
  },
  actions: {
    // get user info
    getInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(response => {
          const { data } = response;

          if (!data) {
            reject('Verification failed, please Login again.')
          }
          // commit('set_user',data)
          commit('set_green_user',data)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  modules: {
  }
})

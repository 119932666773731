import Vue from 'vue'
import VueRouter from 'vue-router'
import  store from '../store/index'
import {Message} from "element-ui";

Vue.use(VueRouter)

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'energyIndex',
    component: () => import('../views/EngeryEvaluation/energyIndex'),
    children: [
      // {
      //   path: '/404',
      //   component: () => import('@/views/404'),
      //   hidden: true
      // },
      {
        path: 'project',
        name: 'project',
        redirect: '/project/projectIdent',
        meta: {
          icon: 'http://cloud.cdmp.tech/c5bfedf80cea4247cd392f03c236d8ec.png',
          icon2: 'http://cloud.cdmp.tech/e1fdd463f62310a18356f49a747a4e30.png',
          active: 1,
          name: '项目库',
          role: [1] 
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'projectIdent',
            name: 'projectIdent',
            meta: {
              active: 1,
              name: '标识项目',
              parent_active: 1,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/projectLibrary/projectIdent')
          },
          {
            path: 'projectRecord',
            name: 'projectRecord',
            meta: {
              active: 2,
              name: '备案项目',
              parent_active: 1,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/projectLibrary/projectRecord')
          },
        ]
      },
      {
        path: 'formal',
        name: 'formal',
        redirect: '/formal/formalPend',
        meta: {
          icon: 'http://cloud.cdmp.tech/582330689909c2edf80df850878f78f4.png',
          icon2: 'http://cloud.cdmp.tech/5d6ee3fa9c71f0ee241e3f0dd0a571db.png',
          active: 2,
          name: '形式审查',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'formalPend',
            name: 'formalPend',
            meta: {
              active: 2,
              name: '待处理',
              parent_active: 2,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/formalPend')
          },
          {
            path: 'stepInformation',
            name: 'stepInformation',
            meta: {
              // active:2,
              name: '',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/stepInformation')
          },
          {
            path: 'formDetail',
            name: 'formDetail',
            meta: {
              active: 2,
              name: '',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/formDetail')
          },
          {
            path: 'totalTable',
            name: 'totalTable',
            meta: {
              active: 2,
              name: '',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/totalTable')
          },
          {
            path: 'ipendTable',
            name: 'ipendTable',
            meta: {
              active: 2,
              name: '',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/ipendTable')
          },
          {
            path: 'reportTable',
            name: 'reportTable',
            meta: {
              active: 2,
              name: '',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/reportTable')
          },
          {
            path: 'formalDistri',
            name: 'formalDistri',
            meta: {
              active: 1,
              name: '审查分配',
              parent_active: 2,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/formalDistri')
          },

        ]
      },

      {
        path: 'technology',
        name: 'technology',
        redirect: '/technology/technicalIndex',
        meta: {
          icon: 'http://cloud.cdmp.tech/824f9c8d2c24d84b5f63eb6a48bcb1fd.png',
          icon2: 'http://cloud.cdmp.tech/42c9750ed7bad93d8d0249c5fe3e094d.png',
          active: 3,
          name: '技术审查',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'technicalIndex',
            name: 'technicalIndex',
            meta: {
              active: 1,
              // name: '标识项目',
              parent_active: 3,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/technicalIndex')
          },
          {
            path: 'techInformation',
            name: 'techInformation',
            meta: {
              active: 2,
              name: '',
              parent_active: 3,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/techInformation')
          },
          {
            path: 'teexportTable',
            name: 'teexportTable',
            meta: {
              active: 2,
              name: '',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/teexportTable')
          },
        ]
      },

      {
        path: 'announcement',
        name: 'announcement',
        redirect: '/announcement/publicIndex',
        meta: {
          icon: 'http://cloud.cdmp.tech/4fd0faf2682c440833747eb0c8b2efe3.png',
          icon2: 'http://cloud.cdmp.tech/059cff1a5608beedf500664dc377a826.png',
          active: 4,
          name: '公示公告',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'publicIndex',
            name: 'publicIndex',
            meta: {
              active: 1,
              // name: '标识项目',
              parent_active: 4,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/publicAnnounced/publicIndex')
          },
        ]
      },
      {
        path: 'mark',
        name: 'mark',
        redirect: '/mark/identMake',
        meta: {
          icon: 'http://cloud.cdmp.tech/d09ab5134914492f1333427ebafabf34.png',
          icon2: 'http://cloud.cdmp.tech/5ec59a155ef628b874de1d344e3187de.png',
          active: 5,
          name: '标识发放',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'identMake',
            name: 'identMake',
            meta: {
              active: 1,
              name: '标识制作',
              parent_active: 5,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/identIssue/identMake')
          },
          {
            path: 'identSend',
            name: 'identSend',
            meta: {
              active: 2,
              name: '标识寄取',
              parent_active: 5,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/identIssue/identSend')
          },
          {
            path: 'formDetail',
            name: 'formDetail',
            meta: {
              active: 1,
              name: '',
              parent_active: 5,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/formalReview/formDetail')
          },
        ]
      },
      {
        path: 'statistics',
        name: 'statistics',
        redirect: '/statistics/areaCount',
        meta: {
          icon: 'https://cloud.cdmp.tech/9f5975b9c16ab087afeb82f528fca3aa.png',
          icon2: 'https://cloud.cdmp.tech/2ce9bd9abe3f384b1d9f1fe0868889f6.png',
          active: 6,
          name: '数据看板',
          role: [1, 4]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'areaCount',
            name: 'areaCount',
            meta: {
              active: 1,
              name: '地区统计',
              parent_active: 6,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/dataChart/areaCount')
          },
          {
            path: 'yearsCount',
            name: 'yearsCount',
            meta: {
              active: 2,
              name: '年度统计',
              parent_active: 6,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/dataChart/yearsCount')
          },
          {
            path: 'identCount',
            name: 'identCount',
            meta: {
              active: 4,
              name: '标识等级统计',
              parent_active: 6,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/dataChart/identCount')
          },
          {
            path: 'dataCount',
            name: 'dataCount',
            meta: {
              active: 3,
              name: '数据分析',
              parent_active: 6,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/dataChart/dataCount')
          },

        ]
      },
      {
        path: 'user',
        name: 'user',
        redirect: '/user/accontim',
        meta: {
          icon: 'https://cloud.cdmp.tech/59513d81626e53beb3e2a991b1b03e37.png',
          icon2: 'https://cloud.cdmp.tech/448afcce93f2802c0e77cf078d93abe7.png',
          active: 7,
          name: '用户管理',
          role: [1]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'accontim',
            name: 'accontim',
            meta: {
              active: 1,
              name: '账号信息',
              parent_active: 7,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/caccount/accontim')
          },
          {
            path: 'qiyeuserList',
            name: 'qiyeuserList',
            meta: {
              active: 2,
              name: '企业用户',
              parent_active: 7,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/caccount/qiyeuserList')
          },
          {
            path: 'qiyeuser',
            name: 'qiyeuser',
            meta: {
              active: 2,
              name: '企业用户',
              parent_active: 7,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/caccount/qiyeuser')
          },
          {
            path: 'exportuser',
            name: 'exportuser',
            meta: {
              active: 3,
              name: '专家用户',
              parent_active: 7,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/caccount/exportuser')
          },
        ]
      },

      // 企业

      {
        path: 'user_project',
        name: 'user_project',
        redirect: '/user_project/itemIdent',
        meta: {
          icon: 'http://cloud.cdmp.tech/c5bfedf80cea4247cd392f03c236d8ec.png',
          icon2: 'http://cloud.cdmp.tech/e1fdd463f62310a18356f49a747a4e30.png',
          active: 1,
          name: '项目库',
          role: [3]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'ipTable',
            name: 'ipTable',
            meta: {
              active: 1,
              name: '待处理',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/ipTable')
          },
          {
            path: 'ipTotaltable',
            name: 'ipTotaltable',
            meta: {
              active: 1,
              name: '待处理',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/ipTotaltable')
          },
          {
            path: 'ipFormtedail',
            name: 'ipFormtedail',
            meta: {
              active: 1,
              name: '待处理',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/ipFormtedail')
          },
          {
            path: 'itemIdent',
            name: 'itemIdent',
            meta: {
              active: 1,
              name: '标识项目',
              parent_active: 1,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/item/itemIdent')
          },
          {
            path: 'itemRecord',
            name: 'itemRecord',
            meta: {
              active: 2,
              name: '备案项目',
              parent_active: 1,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/item/itemRecord')
          },
          {
            path: 'itemRecordDetail',
            name: 'itemRecordDetail',
            meta: {
              active: 2,
              name: '备案项目',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/item/itemRecordDetail')
          },
          {
            path: 'ipImformation',
            name: 'ipImformation',
            meta: {
              active: 1,
              name: '待处理',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/ipImformation')
          },
        ]

      },
      {
        path: 'user_mark',
        name: 'user_mark',
        redirect: '/user_mark/itemdeclare',
        meta: {
          icon: 'https://cloud.cdmp.tech/61a56b0001f97f692078a76cbff4d34f.png',
          icon2: 'https://cloud.cdmp.tech/3db50f9594f1954f5d1e4c050f2ad68c.png',
          active: 2,
          name: '标识申报',
          role: [3]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'itemdeclare',
            name: 'itemdeclare',
            meta: {
              active: 1,
              name: '项目申报',
              parent_active: 2,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/itemdeclare')
          },
          {
            path: 'identpend',
            name: 'identpend',
            meta: {
              active: 2,
              name: '待处理',
              parent_active: 2,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/identpend')
          },
          {
            path: 'itdeAdd',
            name: 'itdeAdd',
            meta: {
              active: 1,
              name: '项目申报',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/itdeAdd')
          },
          {
            path: 'declareTable',
            name: 'declareTable',
            meta: {
              active: 1,
              name: '项目申报',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/declareTable')
          },
          {
            path: 'declareTableTwo',
            name: 'declareTableTwo',
            meta: {
              active: 1,
              name: '项目申报',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/declareTableTwo')
          },
          {
            path: 'nextTable',
            name: 'nextTable',
            meta: {
              active: 1,
              name: '项目申报',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/nextTable')
          },
          {
            path: 'nextTableTwo',
            name: 'nextTableTwo',
            meta: {
              active: 1,
              name: '项目申报',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/nextTableTwo')
          },
          {
            path: 'ipImformation',
            name: 'ipImformation',
            meta: {
              active: 2,
              name: '待处理',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/ipImformation')
          },
          {
            path: 'ipreporttable',
            name: 'ipreporttable',
            meta: {
              active: 2,
              name: '待处理',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/ipreporttable')
          },
          {
            path: 'ipTotaltable',
            name: 'ipTotaltable',
            meta: {
              active: 2,
              name: '待处理',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/ipTotaltable')
          },
          {
            path: 'ipFormtedail',
            name: 'ipFormtedail',
            meta: {
              active: 2,
              name: '待处理',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/ipFormtedail')
          },
          {
            path: 'ipTable',
            name: 'ipTable',
            meta: {
              active: 2,
              name: '待处理',
              parent_active: 2,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/ident/ipTable')
          },
        ]

      },
      {
        path: 'user_project1',
        name: 'user_project1',
        redirect: '/user_project1/projectRecord',
        meta: {
          icon: 'https://cloud.cdmp.tech/2de9333339f911ec132cfb5d3906a9fe.png',
          icon2: 'https://cloud.cdmp.tech/1328a0dac14d378ff643c1c57a9a2058.png',
          active: 3,
          name: '项目备案',
          role: [3]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'projectRecord',
            name: 'projectRecord',
            meta: {
              active: 1,
              name: '备案项目',
              parent_active: 3,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/record/projectRecord')
          },
          {
            path: 'prorecAdd',
            name: 'prorecAdd',
            meta: {
              active: 1,
              name: '备案项目',
              parent_active: 3,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/record/prorecAdd')
          },
        ]

      },
      {
        path: 'user_user',
        name: 'user_user',
        redirect: '/user_user/enInformation',
        meta: {
          icon: 'https://cloud.cdmp.tech/e7d92c6c6919a3639422449cebc3b036.png',
          icon2: 'https://cloud.cdmp.tech/a945b5cc646bbfd0fa07152cf549d27a.png',
          active: 4,
          name: '账户管理',
          role: [3]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'enInformation',
            name: 'enInformation',
            meta: {
              active: 1,
              name: '企业信息',
              parent_active: 4,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/enteraccount/enInformation')
          },
          {
            path: 'enchildmanage',
            name: 'enchildmanage',
            meta: {
              active: 2,
              name: '子账户管理',
              parent_active: 4,
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/enteraccount/enchildmanage')
          },
          {
            path: 'enchilditem',
            name: 'enchilditem',
            meta: {
              active: 2,
              name: '子账户管理',
              parent_active: 4,
              hidden: "true"
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/enteraccount/enchilditem')
          },
          {
            path: 'enchildrecord',
            name: 'enchildrecord',
            meta: {
              active: 2,
              name: '子账户管理',
              parent_active: 4,
              hidden: "true"
            },
            component: () => import('../views/EngeryEvaluation/enterpriseMange/enteraccount/enchildrecord')
          },

        ]

      },
      // 专家
      {
        path: 'export',
        name: 'export',
        redirect: '/export/exportIpend',
        meta: {
          icon: 'http://cloud.cdmp.tech/c5bfedf80cea4247cd392f03c236d8ec.png',
          icon2: 'http://cloud.cdmp.tech/e1fdd463f62310a18356f49a747a4e30.png',
          active: 1,
          name: '专家审查',
          role: [2]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'exportIpend',
            name: 'exportIpend',
            meta: {
              active: 1,
              name: '待处理',
              parent_active: 1,
            },
            component: () => import('../views/EngeryEvaluation/exportManage/exportIpend')
          },
          {
            path: 'teexportTable',
            name: 'teexportTable',
            meta: {
              active: 2,
              name: '',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/centerMange/technicalReview/teexportTable')
          },
          {
            path: 'exreportTable',
            name: 'exreportTable',
            meta: {
              active: 1,
              name: '',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/exportManage/exreportTable')
          },
          {
            path: 'exportIpendDeatil',
            name: 'exportIpendDeatil',
            meta: {
              active: 1,
              name: '',
              parent_active: 1,
              hidden: true,
            },
            component: () => import('../views/EngeryEvaluation/exportManage/exportIpendDeatil')
          },
          {
            path: 'exportProce',
            name: 'exportProce',
            meta: {
              active: 2,
              name: '已处理',
              parent_active: 1,
            },
            component: () => import('../views/EngeryEvaluation/exportManage/exportProce')
          },
        ]
      },
      {
        path: 'export_user',
        name: 'export_user',
        redirect: '/export_user/exportmange',
        meta: {
          icon: 'http://cloud.cdmp.tech/b2227a09578e6b3a5818fbb06aed9322.png',
          icon2: 'https://cloud.cdmp.tech/a40be1c0f01381ef735cace670be161e.png',
          active: 2,
          name: '账号管理',
          role: [2]
        },
        component: () => import('../views/EngeryEvaluation/components/second_layout'),
        children: [
          {
            path: 'exportmange',
            name: 'exportmange',
            meta: {
              active: 1,
              name: '',
              parent_active: 2,
            },
            component: () => import('../views/EngeryEvaluation/exportManage/exportmange')
          },
        ]

      },

    ]
  },
  {
    path: "/login",
    name: '登录',
    component: () => import('../views/Login')
  }
]

const router = new VueRouter({
  routes
})
// router.beforeEach((to, from, next) => {
//   if (to.path != '/404') {
//     next('/404')
//   } else {
//     next();
//   }
// })
// 增加路由守卫
router.beforeEach(async (to, from, next) => {
  if (to.query.token) {
    store.commit('set_token', to.query.token);
    await store.dispatch('getInfo')
  }
  if (to.path == '/login') {
    next();
  } else {
    if (!store.state.token || store.state.token == '') {
      let user = localStorage.getItem('energy_user');
      if (user && user != '') {
        store.commit('set_user', JSON.parse(user));
      }
    }
    if (store.state.token && store.state.token != '') {
      if (to.name == 'energyIndex') {
        if (store.state.role.indexOf(1) != -1) {
          next({path: '/project/projectIdent'})
        } else if (store.state.role.indexOf(3) != -1) {
          next('/user_project/itemIdent')
        } else if (store.state.role.indexOf(4) != -1) {
          next('/statistics/areaCount')
        } else {
          next('/export/exportIpend')
        }
      } else {
        next();
      }
    } else {
      Message.warning('请先登录');
      next({path: '/login'})
    }

  }
})

export default router

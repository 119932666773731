import request from "../utils/request";

export function login(data) {
    return request({
        url: '/api/public/login',
        method: 'post',
        data
    })
}

export function getInfo(token) {
    return request({
        url: '/api/public/info',
        method: 'get',
        params: { token }
    })
}


export function getStaff(data) {
    return request({
        url: '/api/energy/staff',
        method: 'get',
        params: data
    })
}

export function resetPasswordApi(data) {
    return request({
        url: '/api/energy/reset',
        method: 'get',
        params: data
    })
}
